import axios from 'axios';


const BASE_URL = process.env.REACT_APP_BASEURL;
const GetAppTokenId = process.env.REACT_APP_API_APPID;
const GetAppToken = process.env.REACT_APP_API_TOKEN;


console.log ('GetAppTokenId', GetAppToken)
class APIService {
    constructor(baseURL) {
        this.baseURL = baseURL || BASE_URL;
        this.api = axios.create({
          baseURL: this.baseURL
        });
    
        // Define your endpoints here
        this.endpoints = {

          // ---------- expiredFilter List API ------------
          expiredfilterApiList: "api/v1/expired-jobs/filter",

          // ---------- Jobs View & List API ------------
          // jobApiView: "api/v1/job",
          jobApiList: "api/v1/job",
          // jobApiFilter:"api/v1/jobs/filter",
          jobApiFilter:"api/v1/jobs/new-filter",

          

          // ---------- Location API List & View  ------------
          locationApiList: "api/v1/locations",
          locationApiView: "api/v1/locations/",

          // ---------- City API List  ------------
          cityApi: "api/v1/city",

          // ---------- Job Filter API List  ------------
          jobFilterApiList: "api/v1/jobs/filter",

          // ---------- Department API List  ------------
          departmentApiList: "api/v1/department",

          // ---------- Branch API List  ------------
          branchApiList: "api/v1/branch",
          
          // ---------- Designation API List  ------------
          designationApiList: "api/v1/designation",

          // ---------- Default Image API List  ------------
          defaultImageApiList: "api/v1/default-image",

          // ---------- Question Template API List  ------------
          questionTemplateApiList: "api/v1/question-group",

          // ---------- Applicant Status API View  ------------
          applicantStatusApiView: "api/v1/applicant-status/",

          // ---------- Seo Keyword API View  ------------
          seoKeywordApiView: "api/v1/seo-keyword/",

          // ---------- Seo Graph API View  ------------
          seoGraphApiView: "api/v1/seo-graph",

          // ---------- Source API View  ------------
          sourceApiView: "api/v1/source",

          // ---------- createJobApplication API ------------
          createJobApplication: "api/v1/job-application",
          // https://gatewayapi.eela.tech/api/v1/job-application?app=1234560
        };
      }
     
      async get(endpointKey, params = {}, searchParam = null) {
        try {
          let endpoint = this.endpoints[endpointKey];
         
          params.app = GetAppTokenId; // Note: Use function call instead of invoking directly
    
          const response = await this.api.get(endpoint, {
            params,
            headers: { Authorization: 'Bearer ' + GetAppToken },
          });
          return response.data; // Return data directly
        } catch (error) {
          throw new Error('API request failed:', error);
        }
      }

      async view(endpointKey,id=null, params = {}, searchParam = null) {
        try {
          let endpoint = this.endpoints[endpointKey];
          if (id) {
            endpoint = endpoint + '/' + id;
          }
          params.app = GetAppTokenId; // Note: Use function call instead of invoking directly
    
          const response = await this.api.get(endpoint, {
            params,
            headers: { Authorization: 'Bearer ' + GetAppToken },
          });
          return response.data; // Return data directly
        } catch (error) {
          throw new Error('API request failed:', error);
        }
      }
    
      async post(endpointKey, data = null, params={}, searchParam = null) {
        console.debug("data",data)
        try {
          let endpoint = this.endpoints[endpointKey];
          if (searchParam) {
            endpoint = endpoint + '/' + searchParam;
          }
          data.app = GetAppTokenId;
          params.app = GetAppTokenId;
          const response = await this.api.post(endpoint, data, {
            params,
            headers: { Authorization: 'Bearer ' + GetAppToken }
          });
          return response;
        } catch (error) {
          throw new Error('API request failed:', { error });
        }
      }
      
      async delete(endpointKey, params = {}, searchParam = null) {
        try {
          let endpoint = this.endpoints[endpointKey];
          if (searchParam) {
            endpoint = endpoint + '/' + searchParam;
          }
          params.app = GetAppTokenId();
          const response = await this.api.delete(endpoint, {
            params,
            headers: { Authorization: 'Bearer ' + GetAppToken() }
          });
          return response;
        } catch (error) {
          throw new Error('API request failed:', error);
        }
      }
    
      async put(endpointKey, data = null, searchParam = null) {
        try {
          let endpoint = this.endpoints[endpointKey];
          if (searchParam) {
            endpoint = endpoint + '/' + searchParam;
          }
          data['app'] = GetAppTokenId();
          const response = await this.api.put(endpoint, data, {
            headers: { Authorization: 'Bearer ' + GetAppToken() }
          });
          return response;
        } catch (error) {
          throw new Error('API request failed:', { error });
        }
      }
}
export default new APIService(BASE_URL);